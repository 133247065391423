import {
  FormControl,
  FormLabel,
  Skeleton,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { ContextType } from "modules/auth/application/types/IContext";
import { IOrganizationConfiguration } from "modules/organization/application/types/IOrganization";
import { useMarkAsDemoAccess } from "modules/organization/infrastructure/useMarkAsDemoAccess";

interface IProps {
  organizationId: string;
  configuration: IOrganizationConfiguration;
  isLoading: boolean;
}

export const MarkAsDemo = ({
  organizationId,
  configuration,
  isLoading,
}: IProps) => {
  const contextType = ContextMachineContext.useSelector(
    (state) => state.context
  ).userInfo?.currentlySelectedContext?.type;
  const { mutateAsync: markAsDemoAccess } = useMarkAsDemoAccess(organizationId);
  const isMutating = useIsMutating({
    mutationKey: ["allow-user-password-change"],
  });

  const allowedContextTypes: ContextType[] = ["system_admin", "system_manager"];

  if (!allowedContextTypes.includes(contextType!)) {
    return null;
  }

  if (isLoading) {
    return (
      <VStack align="stretch">
        <Skeleton height="30px" />
        <Skeleton height="50px" />

        <Skeleton height="30px" mt={4} />
        <Skeleton height="50px" />
      </VStack>
    );
  }

  return (
    <>
      <VStack align="stretch">
        <FormControl display="flex" alignItems="center" gap="2">
          <Switch
            id="mark-as-demo-access-switch"
            name="mark-as-demo-access-switch"
            colorScheme="purple"
            isChecked={configuration.demoAccess}
            isDisabled={!!isMutating}
            onChange={async () => {
              try {
                await markAsDemoAccess({
                  demoAccess: !configuration.demoAccess,
                  withVisualEffects: configuration.demoAccessWithVisualEffects,
                });
              } catch (e) {
                toastWithError({ error: e });
              }
            }}
          />
          <FormLabel
            htmlFor="mark-as-demo-access-switch"
            mb="0"
            fontWeight="bold"
          >
            {t("Wersja demonstracyjna")}
          </FormLabel>
        </FormControl>
        <Text fontSize="sm" color="gray.600">
          {t(
            "Włączenie tej funkcji spowoduje oznaczenie podmiotu jako korzystającego z wersji demonstracyjnej na liście podmiotów."
          )}
        </Text>
      </VStack>
      <VStack align="stretch">
        <FormControl display="flex" alignItems="center" gap="2">
          <Switch
            id="mark-as-demo-access-with-visual-effects-switch"
            name="mark-as-demo-access-with-visual-effects-switch"
            colorScheme="purple"
            isChecked={configuration.demoAccessWithVisualEffects}
            isDisabled={!!isMutating || !configuration.demoAccess}
            onChange={async () => {
              try {
                await markAsDemoAccess({
                  demoAccess: true,
                  withVisualEffects: !configuration.demoAccessWithVisualEffects,
                });
              } catch (e) {
                toastWithError({ error: e });
              }
            }}
          />
          <FormLabel
            htmlFor="mark-as-demo-access-with-visual-effects-switch"
            mb="0"
            fontWeight="bold"
          >
            {t("Znak wodny wersji demonstracyjnej")}
          </FormLabel>
        </FormControl>
        <Text fontSize="sm" color="gray.600">
          {t(
            "Włączenie tej opcji spowoduje oznaczenie raportu z weryfikacji znakiem wodnym."
          )}
        </Text>
      </VStack>
    </>
  );
};
