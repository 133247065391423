import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { EditIcon } from "components/icons/EditIcon";

interface IProps {
  keyId: string;
  keyName: string;
}

export const EditApiKeyButton = (props: IProps) => {
  const id = `edit-api-key`;
  const onOpen = useOpenDialog(id);

  return (
    <Tooltip label={t("Edytuj")} aria-label={t("Edytuj klucz api")}>
      <IconButton
        variant="link"
        colorScheme="purple"
        size="sm"
        data-testid="edit-api-key"
        onClick={() => onOpen(props)}
        aria-label={t("Edytuj klucz api")}
        icon={<EditIcon />}
      />
    </Tooltip>
  );
};
