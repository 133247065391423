import { IVerificationHistoryRecord } from "./types/IVerificationHistoryRecord";

export const generateVerificationStatsCSV = (
  verifications: IVerificationHistoryRecord[]
) => {
  const headers = [
    // global
    "Type",
    "Verification ID",
    // stats
    "Signatures Count",
    "Files Count",
    // timing
    "Sent At",
    "Verification Started At",
    "Verification Finished At",
    "Verification Duration",
    "Total Duration",
    // file status durations
    "File Path Name",
    "DSS Duration",
    "PHP Duration",
    "Total Duration (Seconds)",
  ];

  const rows = verifications.flatMap((verification) => {
    const timing = verification.timing;
    const stats = verification.stats;

    const globalInfo = [verification.type, verification.verificationId];

    const timingInfo = [
      timing?.sentAt || "Brak danych",
      timing?.verificationStartedAt || "Brak danych",
      timing?.verificationFinishedAt || "Brak danych",
      timing?.verificationDuration ?? "Brak danych",
      timing?.totalDuration ?? "Brak danych",
    ];

    const statsInfo = [
      stats?.signaturesCount ?? "Brak danych",
      stats?.filesCount ?? "Brak danych",
    ];

    // Generowanie wierszy dla fileStatusDurations
    const fileRows = timing?.fileStatusDurations.map((file, index) => {
      if (index !== 0) {
        return [
          "",
          "",
          // stats
          "",
          "",
          // timing
          "",
          "",
          "",
          "",
          "",
          file.filePathName,
          file.dssDuration,
          file.phpDuration,
          file.totalDurationInSeconds,
        ];
      }

      return [
        ...globalInfo,
        ...statsInfo,
        ...timingInfo,
        file.filePathName,
        file.dssDuration,
        file.phpDuration,
        file.totalDurationInSeconds,
      ];
    }) || [
      // Jeśli brak plików, dodaj pusty wiersz dla globalnych danych
      [
        ...globalInfo,
        ...statsInfo,
        ...timingInfo,
        "Brak danych",
        "Brak danych",
        "Brak danych",
        "Brak danych",
      ],
    ];

    return fileRows;
  });

  const csvContent = [
    headers.join(","),
    ...rows.map((row) => row.join(",")),
  ].join("\n");

  return csvContent;
};
