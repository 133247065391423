import { useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Td,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { FormatDateTime } from "components/FormatDateTime";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { IInterpretation } from "modules/verification/application/types/IInterpretation";
import { useOrganizationVerificationInterpretationsQuery } from "modules/verification/infrastructure/useOrganizationVerificationInterpretationsQuery";

import { ShowInterpretationDetailsBtn } from "./ShowInterpretationDetailsBtn";

const columnsHelper = createColumnHelper<IInterpretation>();
const columns = [
  columnsHelper.accessor("context.name", {
    header: () => t("Nazwa konta"),
    cell: (info) => (
      <Td whiteSpace="normal">
        {info.getValue() || (
          <Text color="gray.600" fontStyle="italic">
            {t("Usunięty")}
          </Text>
        )}
      </Td>
    ),
  }),
  columnsHelper.accessor("details.name", {
    header: () => t("Imię i nazwisko"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnsHelper.accessor("createdAt", {
    header: () => t("Data zapytania"),
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDateTime date={info.getValue()} />
      </Td>
    ),
  }),
  columnsHelper.accessor("details.content", {
    header: () => t("Treść"),
    cell: (info) => (
      <Td>
        <Tooltip label={info.getValue()}>
          <Text w="400px" isTruncated>
            {info.getValue()}
          </Text>
        </Tooltip>
      </Td>
    ),
  }),
  columnsHelper.accessor("id", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <ShowInterpretationDetailsBtn {...info.row.original} />
      </Td>
    ),
  }),
];

interface IProps {
  interpretations: IInterpretation[];
  isLoading: boolean;
}

const InterpretationRequestHistoryTableConnected = ({
  interpretations,
  isLoading,
}: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (interpretations?.length === 0) {
    return (
      <TableEmptyState title={t("Nie wykonano jeszcze żadnych weryfikacji")} />
    );
  }

  const sortedInterpretations = reverse(
    sortBy(interpretations, function (data) {
      return new Date(data.createdAt);
    })
  );
  return (
    <Table<IInterpretation> data={sortedInterpretations} columns={columns} />
  );
};

export const InterpretationRequestHistoryTable = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useOrganizationVerificationInterpretationsQuery(
    organizationId!
  );
  return (
    <Card id="interpretation-request-history-table" variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">
          {t("Wykonane zapytania o interpretację")}
        </Heading>
      </CardHeader>
      <CardBody>
        <InterpretationRequestHistoryTableConnected
          interpretations={data?.interpretations}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};
