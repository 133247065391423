import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { PlusIcon } from "components/icons/PlusIcon";

import { GenerateApiKeyModal } from "./GenerateApiKeyModal";

interface IProps {
  isActive: boolean;
  hasApiAccess: boolean;
  hasActiveTariff: boolean;
}

export const GenerateApiKeyButton = ({
  isActive,
  hasApiAccess,
  hasActiveTariff,
}: IProps) => {
  const id = `generate-api-key`;
  const onOpen = useOpenDialog(id);

  if (!hasApiAccess || !hasActiveTariff || !isActive) {
    return null;
  }

  return (
    <>
      <GenerateApiKeyModal id={id} />
      <Button
        size="sm"
        leftIcon={<PlusIcon />}
        colorScheme="purple"
        onClick={onOpen}
      >
        {t("Wygeneruj nowy klucz")}
      </Button>
    </>
  );
};
