import ky from "ky";

interface Settings {
  API_HOST: string | undefined;
  SAWPE_PUBLIC_WIDGET: string | undefined;
  INTERNATIONALIZATION: boolean | undefined;
  VERIFICATION_HOST: string | undefined;
  MERCURE: string | undefined;
  EXTRACTED_FILES: boolean | undefined;
}

export let settings: Settings = {
  API_HOST: undefined,
  SAWPE_PUBLIC_WIDGET: undefined,
  INTERNATIONALIZATION: undefined,
  VERIFICATION_HOST: undefined,
  MERCURE: undefined,
  EXTRACTED_FILES: undefined,
};

const fetchSettings = async (): Promise<Settings> => {
  if (import.meta.env.MODE === "production") {
    return ky("/env.json", {
      headers: {
        "Cache-Control": "no-cache",
      },
    }).json();
  }

  return ky("/env.local.json")
    .catch(() => {
      return ky("/env.json");
    })
    .then((res) => res.json());
};

export const getSettings = async () => {
  return fetchSettings().then((res) => {
    settings = res;
    return res;
  });
};
