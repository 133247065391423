import { useState } from "react";

import {
  Button,
  ButtonGroup,
  ListItem,
  ModalBody as CModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  VStack,
  InputLeftElement,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { t } from "i18next";

import { DebouncedInput } from "components/DebouncedInput";
import { Modal } from "components/Dialog";
import { ClearIcon } from "components/icons/ClearIcon";
import { SearchIcon } from "components/icons/SearchIcon";

const id = `uploaded-files-modal`;

export const UploadedFilesModal = () => {
  return (
    <Modal<string[]> id={id} size="3xl">
      {({ onClose, payload }) => {
        if (!payload) {
          return null;
        }

        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t("Przesłane pliki ({{filesCount}})", {
                  filesCount: payload.length,
                })}
              </ModalHeader>
              <ModalCloseButton />

              <ModalBody stats={payload} />

              <ModalFooter>
                <ButtonGroup gap="2" justifyContent="end">
                  <Button onClick={onClose}>{t("Zamknij")}</Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};

const ModalBody = ({ stats }: { stats: string[] }) => {
  const [value, setValue] = useState("");

  return (
    <CModalBody as={VStack} align="stretch" spacing={6}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon />
        </InputLeftElement>
        <DebouncedInput
          value={value}
          onChange={(e) => {
            if (typeof e === "string") setValue(e);
          }}
          placeholder={t("Nazwa pliku")}
          paddingLeft="36px"
        />
        {value !== "" && (
          <InputRightElement>
            <IconButton
              aria-label="clear"
              h="1.75rem"
              size="sm"
              variant="ghost"
              icon={<ClearIcon />}
              onClick={() => {
                setValue("");
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <OrderedList pl={2}>
        {stats
          .filter((file) => value === "" || file.includes(value))
          .map((file) => (
            <ListItem key={file}>{file}</ListItem>
          ))}
      </OrderedList>
    </CModalBody>
  );
};
