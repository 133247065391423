import { useForm } from "react-hook-form";

import { Input, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";

export interface IApiKeyFormData {
  name: string;
}

interface IProps {
  onClose?: () => void;
  onSubmit: (payload: IApiKeyFormData) => Promise<void>;
  defaultData?: IApiKeyFormData;
}

export const ApiKeyForm = ({ defaultData, onClose, onSubmit }: IProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IApiKeyFormData>({
    defaultValues: defaultData,
  });

  return (
    <form
      id="api-key-form"
      onSubmit={handleSubmit(async (data) => {
        try {
          await onSubmit(data);
          onClose?.();
        } catch (error) {
          toastWithError({
            error,
          });
        }
      })}
    >
      <VStack align="stretch" spacing={6}>
        <FormControl
          formLabel={t("Nazwa klucza API")}
          isRequired
          formErrorMessage={errors.name?.message}
          formHelperText={t(
            "Nazwa klucza będzie służyć do oznaczania instancji integratora. Wyświetli się także w historii weryfikacji oraz w raporcie (EPW)"
          )}
        >
          <Input
            {...register("name", {
              maxLength: {
                value: 255,
                message: t("Maksymalnie 255 znaków"),
              },
              minLength: {
                value: 3,
                message: t("Minimalnie 3 znaki"),
              },
              required: true,
            })}
          />
        </FormControl>
      </VStack>
    </form>
  );
};
