import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";
import { HTTPError } from "ky";

import { Modal } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useAddApiKey } from "modules/apiKey/infrastructure/useAddApiKey";

import { ApiKeyForm, IApiKeyFormData } from "../ApiKeyForm";

interface IProps {
  id: string;
}

export const GenerateApiKeyModal = (props: IProps) => {
  const { mutateAsync: addApiKey } = useAddApiKey();

  const isMutating = useIsMutating({
    mutationKey: ["add-api-key"],
  });

  return (
    <Modal id={props.id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Wygeneruj nowy klucz API")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <Text>
                  {t(
                    "Wygenerowanie nowego klucza spowoduje dezaktywację obecnie aktywnego klucza oraz dodanie nowego aktywnego klucza. "
                  )}
                </Text>
                <ApiKeyForm
                  onClose={onClose}
                  onSubmit={async (data: IApiKeyFormData) => {
                    try {
                      await addApiKey({
                        name: data.name,
                      });
                    } catch (error) {
                      if (error instanceof HTTPError) {
                        const errorJson = await error.response.json<{
                          message: string;
                        }>();

                        if (
                          errorJson.message ===
                          "apiKey.error.organizationDisabled"
                        ) {
                          toastWithError({
                            description: t("Podmiot jest nie aktywny."),
                            error,
                          });
                          return;
                        }
                      }

                      toastWithError({
                        error,
                      });
                    }
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="api-key-form"
                    data-testid="generate-api-key-button"
                    isLoading={!!isMutating}
                  >
                    {t("Wygeneruj nowy klucz API")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
