import { useSearchParams } from "react-router-dom";

import {
  IconButton,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { t } from "i18next";

import { DebouncedInput } from "components/DebouncedInput";
import { ClearIcon } from "components/icons/ClearIcon";
import { SearchIcon } from "components/icons/SearchIcon";

interface IProps
  extends Omit<InputProps, "onChange" | "value" | "placeholder"> {
  globalSearchParam?: string;
  placeholder?: string;
}

export const SearchInput = ({
  globalSearchParam = "globalSearch",
  placeholder = t("Szukaj"),
  ...props
}: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = searchParams.get(globalSearchParam) || "";
  return (
    <InputGroup maxW="322px">
      <InputLeftElement pointerEvents="none">
        <SearchIcon />
      </InputLeftElement>
      <DebouncedInput
        value={value}
        onChange={(e) => {
          if (typeof e === "string") {
            searchParams.set(globalSearchParam, e);
            setSearchParams(searchParams, {
              replace: true,
            });
          }
        }}
        placeholder={placeholder}
        paddingLeft="36px"
        {...props}
      />
      {value !== "" && (
        <InputRightElement>
          <IconButton
            aria-label="clear"
            h="1.75rem"
            size="sm"
            variant="ghost"
            icon={<ClearIcon />}
            onClick={() => {
              searchParams.delete(globalSearchParam);
              setSearchParams(searchParams, {
                replace: true,
              });
            }}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
