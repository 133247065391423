import React, { SVGProps } from "react";

export const DOCIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.186 14.552C11.569 14.552 11.209 15.139 11.209 15.925C11.209 16.716 11.58 17.275 12.192 17.275C12.809 17.275 13.163 16.687 13.163 15.901C13.163 15.175 12.815 14.552 12.186 14.552Z"
        fill="currentColor"
      />
      <path
        d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM9.155 17.454C8.729 17.808 8.082 17.975 7.291 17.975C6.816 17.975 6.481 17.945 6.253 17.915V13.944C6.66191 13.8845 7.07481 13.8567 7.488 13.861C8.256 13.861 8.754 13.999 9.143 14.293C9.563 14.605 9.827 15.103 9.827 15.815C9.827 16.59 9.545 17.124 9.155 17.454ZM12.145 18C10.945 18 10.244 17.094 10.244 15.942C10.244 14.731 11.017 13.826 12.211 13.826C13.452 13.826 14.13 14.755 14.13 15.871C14.129 17.196 13.325 18 12.145 18ZM16.8 17.238C17.075 17.238 17.381 17.177 17.562 17.106L17.7 17.819C17.532 17.903 17.154 17.993 16.663 17.993C15.266 17.993 14.546 17.124 14.546 15.972C14.546 14.593 15.529 13.826 16.753 13.826C17.227 13.826 17.586 13.922 17.748 14.006L17.562 14.732C17.3184 14.6319 17.0574 14.5809 16.794 14.582C16.068 14.582 15.504 15.02 15.504 15.92C15.504 16.729 15.984 17.238 16.8 17.238ZM14 9H13V4L18 9H14Z"
        fill="currentColor"
      />
      <path
        d="M7.58402 14.563C7.38102 14.563 7.24902 14.581 7.17102 14.599V17.244C7.24902 17.262 7.37502 17.262 7.48802 17.262C8.31602 17.268 8.85502 16.813 8.85502 15.847C8.86102 15.007 8.37002 14.563 7.58402 14.563Z"
        fill="currentColor"
      />
    </svg>
  );
};
