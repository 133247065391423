import React, { SVGProps } from "react";

export const HTMLIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM13 4L18 9H13V4ZM8.531 18H7.771V16.589H6.515V18H5.748V14.627H6.515V15.923H7.772V14.627H8.532L8.531 18ZM11.531 15.268H10.61V18H9.844V15.268H8.939V14.627H11.531V15.268ZM14.818 18L14.768 16.709C14.751 16.304 14.738 15.813 14.738 15.322H14.722C14.618 15.753 14.477 16.233 14.347 16.629L13.937 17.945H13.34L12.981 16.638C12.8593 16.2042 12.7572 15.765 12.675 15.322H12.664C12.643 15.778 12.63 16.298 12.605 16.718L12.545 18H11.84L12.056 14.627H13.071L13.402 15.753C13.506 16.144 13.612 16.564 13.686 16.959H13.703C13.798 16.568 13.912 16.123 14.023 15.748L14.382 14.627H15.378L15.563 18H14.818ZM18.252 18H16.144V14.627H16.911V17.359H18.253L18.252 18Z"
        fill="currentColor"
      />
    </svg>
  );
};
