import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  name: string;
  apiKeyId: string;
}

export const useEditApiKey = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["edit-api-key"],
    mutationFn: (variables: MutationVariables) => {
      return client.put<{ name: string }, unknown>(
        `organizations/${organizationId}/apiKeys/${variables.apiKeyId}/changeName`,
        {
          body: {
            name: variables.name,
          },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "apiKeys"],
      });
    },
  });
};
