import { useParams } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { CSVIcon } from "components/icons/CSVIcon";

import { generateVerificationStatsCSV } from "modules/verification/application/generateVerificationStatsCSV";
import { useVerificationsHistoryQuery } from "modules/verification/infrastructure/useVerificationsHistoryQuery";

export const GenerateCSVButton = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data, isLoading } = useVerificationsHistoryQuery(organizationId!);

  if (isLoading) {
    return null;
  }

  return (
    <Button
      size="sm"
      leftIcon={<CSVIcon />}
      colorScheme="purple"
      onClick={() => {
        const csv = generateVerificationStatsCSV(data?.verifications);

        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `verification-stats-${organizationId}.csv`;
        link.click();
      }}
    >
      {t("Pobierz statystyki CSV")}
    </Button>
  );
};
