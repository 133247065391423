import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Box,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";

import { DebouncedInput } from "components/DebouncedInput";
import { ClearIcon } from "components/icons/ClearIcon";
import { SearchIcon } from "components/icons/SearchIcon";

import { FileTreeItem } from "modules/verification/application/types/IVerificationHistoryRecord";

import { FileTreeNode } from "./FileTreeNode";

interface IProps {
  requestFilesTree: FileTreeItem[];
}

export const FileTree = ({ requestFilesTree }: IProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFiles, setFilteredFiles] =
    useState<FileTreeItem[]>(requestFilesTree);
  const [visibleFileCount, setVisibleFileCount] = useState(0);

  const countFiles = useCallback((items: FileTreeItem[]): number => {
    return items.reduce((count, item) => {
      return count + 1 + (item.children ? countFiles(item.children) : 0);
    }, 0);
  }, []);

  const totalFileCount = useMemo(
    () => countFiles(requestFilesTree),
    [requestFilesTree, countFiles]
  );

  const filterFiles = useCallback(
    (items: FileTreeItem[]): FileTreeItem[] => {
      return items.reduce((acc: FileTreeItem[], item) => {
        if (item.fileName.toLowerCase().includes(searchTerm.toLowerCase())) {
          acc.push({
            ...item,
            children: item.children ? filterFiles(item.children) : [],
          });
        } else if (item.children && item.children.length > 0) {
          const filteredChildren = filterFiles(item.children);
          if (filteredChildren.length > 0) {
            acc.push({ ...item, children: filteredChildren });
          }
        }
        return acc;
      }, []);
    },
    [searchTerm]
  );

  useEffect(() => {
    const filtered = filterFiles(requestFilesTree);
    setFilteredFiles(filtered);
    setVisibleFileCount(countFiles(filtered));
  }, [requestFilesTree, filterFiles, countFiles]);

  return (
    <Box>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon />
        </InputLeftElement>
        <DebouncedInput
          value={searchTerm}
          onChange={(e) => {
            if (typeof e === "string") setSearchTerm(e);
          }}
          placeholder={t("Nazwa pliku")}
          paddingLeft="36px"
        />
        {searchTerm !== "" && (
          <InputRightElement>
            <IconButton
              aria-label="clear"
              h="1.75rem"
              size="sm"
              variant="ghost"
              icon={<ClearIcon />}
              onClick={() => {
                setSearchTerm("");
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <Text fontSize="sm" color="gray.600" mt={2} mb={4}>
        {t("Widoczne {{visibleFileCount}} z {{totalFileCount}} plików", {
          visibleFileCount: visibleFileCount,
          totalFileCount: totalFileCount,
        })}
      </Text>
      <Box
        maxH="calc(100vh - 380px)"
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "20px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#d6dee1",
            borderRadius: "20px",
            border: "6px solid transparent",
            backgroundClip: "content-box",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#a8bbbf",
          },
        }}
      >
        {filteredFiles.map((file) => (
          <FileTreeNode key={file.filePathName} file={file} level={0} />
        ))}
      </Box>
    </Box>
  );
};
