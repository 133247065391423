import {
  Box,
  Flex,
  HStack,
  Tag,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { ChevronRightIcon } from "components/icons/ChevronRightIcon";
import { DOCIcon } from "components/icons/DOCIcon";
import { FileIcon } from "components/icons/FileIcon";
import { FolderOpenIcon } from "components/icons/FolderOpenIcon";
import { HTMLIcon } from "components/icons/HTMLIcon";
import { JSONIcon } from "components/icons/JSONIcon";
import { PDFIcon } from "components/icons/PDFIcon";

import { FileTreeItem } from "modules/verification/application/types/IVerificationHistoryRecord";

interface IProps {
  file: FileTreeItem;
  level: number;
}

export const FileTreeNode = ({ file, level }: IProps) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const hasChildren = file.children && file.children.length > 0;

  return (
    <>
      <Flex
        alignItems="center"
        onClick={() => {
          if (hasChildren) onToggle();
        }}
        cursor={hasChildren ? "pointer" : "default"}
        _hover={hasChildren ? { bg: "gray.100" } : undefined}
        py={2}
        px={2}
        rounded="md"
        pl={`(${level * 1.5}rem)`}
      >
        {hasChildren ? (
          <ChevronRightIcon
            boxSize={4}
            transform={isOpen ? "rotate(90deg)" : ""}
            mr={1}
          />
        ) : (
          <Box ml={4} mr={1} />
        )}
        <FileTypeIcon fileName={file.fileName} />
        <Box flexGrow={1} ml={2}>
          <VStack align="stretch" spacing={0}>
            <Text
              fontSize="sm"
              fontWeight="medium"
              wordBreak="break-word"
              lineHeight={1.4}
            >
              {file.fileName}
            </Text>
            <Text
              fontSize="xs"
              color="gray.500"
              letterSpacing="1px"
              wordBreak="break-word"
            >
              {file.md5}
            </Text>
            <HStack>
              {file.isSigned && (
                <Tag variant="subtle" size="sm" colorScheme="green">
                  {t("Podpisany")}
                </Tag>
              )}
              {file.hasSignatures && (
                <Tag variant="subtle" size="sm" colorScheme="blue">
                  {t("Zawiera podpisy")}
                </Tag>
              )}
              {file.hasFilesWithSignatures && (
                <Tag variant="subtle" size="sm" colorScheme="purple">
                  {t("Zawiera podpisane pliki")}
                </Tag>
              )}
            </HStack>
          </VStack>
        </Box>
      </Flex>
      {isOpen && hasChildren && (
        <Box ml={4}>
          {file.children.map((childFile) => (
            <FileTreeNode
              key={childFile.filePathName}
              file={childFile}
              level={level + 1}
            />
          ))}
        </Box>
      )}
    </>
  );
};

const FileTypeIcon = ({ fileName }: { fileName: string }) => {
  const extension = getFileExtension(fileName);
  switch (extension) {
    case "pdf":
      return <PDFIcon width="32px" />;
    case "zip":
    case "rar":
    case "7z":
    case "tar":
    case "tgz":
      return <FolderOpenIcon size="32px" />;
    case "html":
      return <HTMLIcon width="32px" />;
    case "json":
      return <JSONIcon width="32px" />;
    case "doc":
    case "docx":
      return <DOCIcon width="32px" />;
    default:
      return <FileIcon width="32px" />;
  }
};

function getFileExtension(fileName: string) {
  const extension = fileName.match(/\.[0-9a-z]+$/i);
  return extension ? extension[0].substring(1) : null;
}
