import { ButtonGroup, Td, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { FormatDate } from "components/FormatDate";
import { FormatDateTime } from "components/FormatDateTime";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { HiddenKey } from "modules/apiKey/presentation/ApiKeysTable/HiddenKey";
import { IWorker } from "modules/workerIntegration/application/types/IWorker";

import { TestWorkerButton } from "./TestWorkerButton";

const columnHelper = createColumnHelper<IWorker>();

const columns = [
  columnHelper.accessor("organizationName", {
    id: "name",
    header: () => t("Nazwa"),
    cell: (info) => (
      <Td data-testid="organization-name" whiteSpace="nowrap" maxW="250px">
        <Text isTruncated>{info.getValue()}</Text>
      </Td>
    ),
  }),
  columnHelper.accessor("key", {
    header: () => t("Klucz"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <HiddenKey apiKey={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("createdAt", {
    header: () => t("Data utworzenia"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDate date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("lastCheckedResponse", {
    header: () => t("Ostatnio przetestowano"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDateTime date={info.getValue()!} seconds />
        <Text textColor="gray.600" data-testid="request-date">
          {info.row.original.lastCheckedRequest &&
            t("request: {{value}}", {
              value: dayjs(info.row.original.lastCheckedRequest).format(
                "DD.MM.YYYY HH:mm:ss"
              ),
            })}
        </Text>
      </Td>
    ),
  }),
  columnHelper.accessor("organizationId", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ButtonGroup>
          <TestWorkerButton organizationId={info.getValue()} />
        </ButtonGroup>
      </Td>
    ),
  }),
];

interface IProps {
  workers: IWorker[];
  isLoading: boolean;
  globalSearchParam: string;
}

export const WorkersTable = ({
  isLoading,
  workers,
  globalSearchParam,
}: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (workers?.length === 0) {
    return <TableEmptyState title={t("Lista integracji jest pusta")} />;
  }

  const sortedRecords = reverse(
    sortBy(workers, function (data) {
      return new Date(data.createdAt!);
    })
  );

  return (
    <Table<IWorker>
      data={sortedRecords}
      columns={columns}
      globalSearchParam={globalSearchParam}
    />
  );
};
