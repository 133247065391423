import React, { SVGProps } from "react";

export const JSONIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.823 15.1219C12.306 15.1219 12.007 15.6129 12.007 16.2679C12.007 16.9289 12.318 17.3939 12.827 17.3939C13.344 17.3939 13.639 16.9039 13.639 16.2479C13.639 15.6439 13.348 15.1219 12.823 15.1219Z"
        fill="currentColor"
      />
      <path
        d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM8.022 16.704C8.022 17.665 7.561 18 6.822 18C6.646 18 6.416 17.971 6.265 17.92L6.351 17.305C6.455 17.34 6.59 17.365 6.742 17.365C7.061 17.365 7.262 17.22 7.262 16.695V14.573H8.023L8.022 16.704ZM9.481 17.995C9.096 17.995 8.715 17.895 8.526 17.79L8.681 17.159C8.885 17.264 9.202 17.37 9.527 17.37C9.877 17.37 10.061 17.224 10.061 17.005C10.061 16.794 9.902 16.674 9.497 16.529C8.935 16.334 8.57 16.023 8.57 15.533C8.57 14.957 9.051 14.516 9.847 14.516C10.227 14.516 10.506 14.596 10.708 14.687L10.536 15.302C10.401 15.237 10.161 15.142 9.831 15.142C9.501 15.142 9.34 15.292 9.34 15.467C9.34 15.682 9.53 15.778 9.967 15.943C10.563 16.163 10.843 16.473 10.843 16.949C10.844 17.515 10.407 17.995 9.481 17.995ZM12.787 18C11.786 18 11.201 17.245 11.201 16.284C11.201 15.272 11.847 14.516 12.843 14.516C13.878 14.516 14.444 15.292 14.444 16.223C14.443 17.33 13.773 18 12.787 18ZM17.734 17.945H16.932L16.211 16.643C15.9959 16.2565 15.8006 15.8594 15.626 15.453L15.61 15.458C15.631 15.903 15.641 16.379 15.641 16.93V17.946H14.94V14.573H15.831L16.532 15.809C16.732 16.163 16.932 16.584 17.084 16.964H17.098C17.048 16.519 17.033 16.064 17.033 15.558V14.573H17.735L17.734 17.945ZM14 9H13V4L18 9H14Z"
        fill="currentColor"
      />
    </svg>
  );
};
