import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";

import { handleVerificationResultStrategy } from "./handleVerificationResultStrategy";
import { EventType, SubscribedEvent } from "./types/IEvent";
import { ISubscriber } from "./types/ISubscriber";
import { useCreateConnectionUrl } from "./useCreateConnectionUrl";

interface IProps {
  children: ReactNode;
}

const EventSourceContext = createContext<
  | {
      subscribe: <T extends EventType>(subscriber: ISubscriber<T>) => void;
      unsubscribe: (id: string) => void;
    }
  | undefined
>(undefined);

export const EventSourceProvider = ({ children }: IProps) => {
  const subscribers = useRef<ISubscriber[]>([]);
  const connectionUrl = useCreateConnectionUrl();

  const subscribe = useCallback(
    <T extends EventType>(subscriber: ISubscriber<T>) => {
      // brzydko :(
      subscribers.current.push(subscriber as unknown as ISubscriber<EventType>);
    },
    []
  );

  const unsubscribe = useCallback((id: string) => {
    subscribers.current = subscribers.current.filter(
      (subscriber) => subscriber.id !== id
    );
  }, []);

  useEffect(() => {
    if (!connectionUrl) {
      return;
    }

    const eventSource = new EventSource(connectionUrl);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data) as SubscribedEvent;

      switch (data.type) {
        case "verification_process_update":
          handleVerificationResultStrategy(subscribers.current, data);
          break;
        case "worker_integration_list_update":
          subscribers.current.forEach((subscriber) => {
            if (subscriber.eventType === "worker_integration_list_update") {
              subscriber.onEvent(data);
            }
          });
          break;
        default:
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-case-declarations
          const eventType: never = data;
          break;
      }
    };

    return () => {
      eventSource.close();
    };
  }, [connectionUrl]);

  return (
    <EventSourceContext.Provider
      value={{
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </EventSourceContext.Provider>
  );
};

export const useEventSourceConsumer = () => {
  const context = useContext(EventSourceContext);
  if (context === undefined) {
    throw new Error(
      "useEventSourceConsumer must be used within a EventSourceProvider"
    );
  }

  return context;
};
