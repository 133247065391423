import { Box, IconButton, Tag, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { UploadIcon } from "components/icons/UploadIcon";

import { IVerificationStats } from "modules/verification/application/types/IVerificationHistoryRecord";

interface IProps {
  stats: IVerificationStats;
}

export const UploadedFilesBtn = ({ stats }: IProps) => {
  const id = `uploaded-files-modal`;
  const onOpen = useOpenDialog(id);

  if (stats.requestFilesCount === null || stats.requestFilesCount === undefined)
    return null;

  return (
    <Tooltip label={t("Przesłane pliki")}>
      <Box position="relative">
        <Tag
          size="sm"
          variant="subtle"
          color="purple.800"
          position="absolute"
          data-testid="uploaded-files-count"
          zIndex={2}
          top={-2}
          right={-2}
        >
          {stats.requestFilesCount}
        </Tag>
        <IconButton
          variant="link"
          colorScheme="purple"
          aria-label={t("Przesłane pliki")}
          icon={<UploadIcon size="24px" />}
          onClick={() => {
            onOpen(stats.requestFiles);
          }}
        />
      </Box>
    </Tooltip>
  );
};
