import { HStack, Text } from "@chakra-ui/react";
import { t } from "i18next";

import { IUsageLimits } from "modules/tariffs/application/types/ITariff";

interface IProps {
  usageStats: IUsageLimits;
}

export const UsageStats = ({ usageStats }: IProps) => {
  return (
    <HStack
      spacing={4}
      fontWeight={400}
      color="gray.600"
      fontSize="sm"
      wrap="wrap"
    >
      <Text data-testid="uploaded-files-count">
        {t("Przesłane pliki: {{count}}", {
          count: usageStats.requestFilesCount,
        })}
      </Text>
      <Text data-testid="files-count">
        {t("Poddane weryfikacji: {{count}}", {
          count: usageStats.filesCount,
        })}
      </Text>
      <Text data-testid="signatures-count">
        {t("Znalezione podpisy: {{count}}", {
          count: usageStats.signaturesCount,
        })}
      </Text>
    </HStack>
  );
};
