import { useParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  name: string;
}

export const useAddApiKey = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["add-api-key"],
    mutationFn: (variables: MutationVariables) => {
      return client.post<{ name: string }, unknown>(
        `organizations/${organizationId}/apiKeys`,
        {
          body: {
            name: variables.name,
          },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "apiKeys"],
      });
    },
  });
};
