import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import { settings } from "settings/getSettings";

import { Modal } from "components/Dialog";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

import { AllowPasswordChange } from "./AllowPasswordChange";
import { EnableExtractSignedFiles } from "./EnableExtractSignedFiles";
import { MarkAsDemo } from "./MarkAsDemo";

interface IProps {
  id: string;
  organizationId: string;
}

export const OrganizationConfigurationModal = ({
  id,
  organizationId,
}: IProps) => {
  const { data, isLoading } = useOrganizationByIdQuery(organizationId);
  const isExtractedFilesEnabled = settings.EXTRACTED_FILES;

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Konfiguracja podmiotu")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <AllowPasswordChange
                  organizationId={organizationId}
                  allowUserPasswordChange={
                    data?.organization.configuration.allowUserPasswordChange
                  }
                  isLoading={isLoading}
                />
                {isExtractedFilesEnabled && (
                  <EnableExtractSignedFiles
                    isLoading={isLoading}
                    organizationId={organizationId}
                    extractSignedFiles={
                      data?.organization.configuration.extractSignedFiles
                    }
                    personalDataClause={
                      !!data?.organization.tariffs.current?.personalDataClause
                    }
                  />
                )}
                <MarkAsDemo
                  organizationId={organizationId}
                  configuration={data?.organization.configuration}
                  isLoading={isLoading}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose}>{t("Zamknij")}</Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
