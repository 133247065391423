import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  isActive: boolean;
}

export const useEnableExtractSignedFiles = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["enable-extract-signed-files"],
    mutationFn: (variables: MutationVariables) => {
      if (variables.isActive) {
        return client.put(
          `organizations/${organizationId}/disableExtractSignedFiles`
        );
      }
      return client.put(
        `organizations/${organizationId}/enableExtractSignedFiles`
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "details"],
      });
    },
  });
};
