import {
  Button,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { Modal } from "components/Dialog";

import { useEditApiKey } from "modules/apiKey/infrastructure/useEditApiKey";

import { ApiKeyForm, IApiKeyFormData } from "../ApiKeyForm";

interface IPayload {
  keyId: string;
  keyName: string;
}

export const EditApiKeyModal = () => {
  const { mutateAsync: editApiKey } = useEditApiKey();

  const isMutating = useIsMutating({
    mutationKey: ["edit-api-key"],
  });

  return (
    <Modal<IPayload> id="edit-api-key">
      {({ onClose, payload }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{t("Edytuj klucz API")}</ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <ApiKeyForm
                  onClose={onClose}
                  onSubmit={async (data: IApiKeyFormData) => {
                    await editApiKey({
                      name: data.name,
                      apiKeyId: payload?.keyId,
                    });
                  }}
                  defaultData={{
                    name: payload?.keyName,
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    form="api-key-form"
                    data-testid="edit-api-key-button"
                    isLoading={!!isMutating}
                  >
                    {t("Zapisz")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
