import {
  FormControl,
  FormLabel,
  Skeleton,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useEnableExtractSignedFiles } from "modules/organization/infrastructure/useEnableExtractSignedFiles";

interface IProps {
  organizationId: string;
  isLoading: boolean;
  extractSignedFiles: boolean;
  personalDataClause: boolean;
}

export const EnableExtractSignedFiles = ({
  isLoading,
  extractSignedFiles,
  organizationId,
  personalDataClause,
}: IProps) => {
  const { mutateAsync: toggle } = useEnableExtractSignedFiles(organizationId);
  const isMutating = useIsMutating({
    mutationKey: ["enable-extract-signed-files"],
  });

  if (!personalDataClause) {
    return null;
  }

  if (isLoading) {
    return (
      <VStack align="stretch">
        <Skeleton height="30px" />
        <Skeleton height="80px" />
      </VStack>
    );
  }

  return (
    <VStack align="stretch">
      <FormControl display="flex" alignItems="center" gap="2">
        <Switch
          id="enable-extract-signed-files"
          name="enable-extract-signed-files"
          colorScheme="purple"
          isChecked={extractSignedFiles}
          isDisabled={!!isMutating}
          onChange={async () => {
            try {
              await toggle({
                isActive: extractSignedFiles,
              });
            } catch (e) {
              toastWithError({ error: e });
            }
          }}
        />
        <FormLabel
          htmlFor="enable-extract-signed-files"
          mb="0"
          fontWeight="bold"
        >
          {t("Wyodrębnianie plików z podpisu")}
        </FormLabel>
      </FormControl>
      <Text fontSize="sm" color="gray.600">
        {t(
          "Funkcja wyodrębniania plików umożliwia pobranie dokumentów osadzonych w podpisie XAdES Enveloping jako oddzielne pliki. Pliki będzie można pobrać przez godzinę po ich wyodrębnieniu na widoku wyniku weryfikacji."
        )}
      </Text>
    </VStack>
  );
};
