import { useParams } from "react-router-dom";

import {
  Box,
  Heading,
  Text,
  Code,
  UnorderedList,
  ListItem,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { t } from "i18next";
import { settings } from "settings/getSettings";

import { useTariffsQuery } from "modules/tariffs/infrastructure/useTariffsQuery";

// może w przyszłości to będzie dynamiczne dla enterpriseów
const customPart = "widget";

export default () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useTariffsQuery(organizationId!);
  const isExtractedFilesEnabled = settings.EXTRACTED_FILES;

  if (isLoading || !data?.tariffs.current) {
    return null;
  }

  return (
    <VStack spacing={8} align="stretch" p={8}>
      <Heading as="h1" size="2xl" mb={8}>
        {t("Dokumentacja API")}
      </Heading>

      {/* Introduction Section */}
      <Box mb={8}>
        <Heading as="h2" size="lg" mb={4}>
          {t("Wstęp")}
        </Heading>
        <Text mb={2}>
          {t(
            "Platforma weryfikacjapodpisu.pl umożliwia weryfikowanie plików poprzez API oraz pobieranie wyników archiwalnych w postaci HTML, PDF i JSON."
          )}
        </Text>
      </Box>
      <Divider />

      {/* Authorization Section */}
      <Box mb={8}>
        <Heading as="h2" size="lg" mb={4}>
          {t("Autoryzacja")}
        </Heading>
        <Text mb={2}>
          {t(
            'Autoryzacja odbywa się poprzez przekazanie klucza autoryzacyjnego w nagłówku api-auth. Klucz API możesz wygenerować w panelu administracyjnym w sekcji "Klucze API".'
          )}
        </Text>
        <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
          {t(
            `curl "https://${customPart}.weryfikacjapodpisu.pl/api/<PATH>" -H "api-auth: <API_KEY>"`
          )}
        </Code>
      </Box>
      <Divider />

      {/* Verification Section */}
      <Box mb={8}>
        <Heading as="h2" size="lg" mb={4}>
          {t("Weryfikacja")}
        </Heading>
        <Heading as="h3" size="md" mb={2}>
          POST
        </Heading>
        <Text mb={2}>
          {t("Ten endpoint pozwala przesłać pliki do weryfikacji.")}
        </Text>
        <Text mb={2}>
          {t(
            "Istnieje możliwość przesłania opcjonalnych tablic white_list i black_list z nazwami plików. Lista przesyłanych plików do weryfikatora będzie wtedy okrojona: dodane zostaną tylko pliki z white listy (jeżeli jest ustawiona) i usunięte pliki znajdujące się na black liście."
          )}
        </Text>
        <Text fontWeight="bold" mt={4}>
          HTTP Request:
        </Text>
        <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
          {`POST https://${customPart}.weryfikacjapodpisu.pl/api/verify`}
        </Code>
        <Text fontWeight="bold" mt={4}>
          Parameters:
        </Text>
        <UnorderedList>
          <ListItem>
            <Text as="span" fontWeight="bold">
              white_list
            </Text>
            :{" "}
            {t(
              "tablica z nazwami plików, które powinny być zweryfikowane (parametr opcjonalny)"
            )}
          </ListItem>
          <ListItem>
            <Text as="span" fontWeight="bold">
              black_list
            </Text>
            :{" "}
            {t(
              "tablica z nazwami plików, które nie powinny być weryfikowane (parametr opcjonalny)"
            )}
          </ListItem>
          {isExtractedFilesEnabled && (
            <ListItem>
              <Text as="span" fontWeight="bold">
                extractSignedFiles
              </Text>
              :
              {t(
                " przekazanie wartości true przy tym parametrze spowoduje wyodrębnianie dokumentów osadzonych w podpisie XAdES Enveloping. Pliki będzie można pobrać przez godzinę po ich wyodrębnieniu podając identyfikator wyodrębnionego pliku"
              )}
            </ListItem>
          )}
        </UnorderedList>
        <Text fontWeight="bold" mt={4}>
          Example:
        </Text>
        <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
          {t(
            `curl -F "file[0]=@some_signed_file.xml" -F "file[1]=@signature.xml" -H "api-auth: <API_KEY>" https://${customPart}.weryfikacjapodpisu.pl/api/verify`
          )}
        </Code>
      </Box>
      <Divider />

      {/* Archival Downloads Section */}
      <Box mb={8}>
        <Heading as="h2" size="lg" mb={4}>
          {t("Pobieranie Poświadczenia Weryfikacji")}
        </Heading>
        <Heading as="h3" size="md" mb={2}>
          GET
        </Heading>
        <Text mb={2}>
          {t(
            "Ten endpoint pozwala pobrać archiwalne wyniki przy pomocy identyfikatora weryfikacji."
          )}
        </Text>
        <Text mb={2}>
          {t(
            "Identyfikator weryfikacji można znaleźć w JSONie odpowiedzi serwera po weryfikacji w kluczu idRequest, wydruku PDF lub widoku HTML."
          )}
        </Text>
        <Text mb={2}>
          {t(
            "Istnieje możliwość pobrania wyniku weryfikacji w języku angielskim, należy w tym celu dodać parametr lang do zapytania, tak jak w przykładzie."
          )}
        </Text>
        <Text fontWeight="bold" mt={4}>
          HTTP Request:
        </Text>
        <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
          {`GET https://${customPart}.weryfikacjapodpisu.pl/api/v3/epw/<ID>.<TYPE>?lang=<LANG>&timezone=<TIMEZONE>`}
        </Code>
        <Text mt={4}>
          {t(
            "Ten endpoint zwraca starą wersję raportu. Zostanie wyłączony na początki roku 2025."
          )}
        </Text>
        <Text fontWeight="500">@deprecated</Text>
        <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
          {`GET https://${customPart}.weryfikacjapodpisu.pl/api/v2/epw/<ID>.<TYPE>?lang=<LANG>`}
        </Code>
        <Text fontWeight="bold" mt={4}>
          Parameters:
        </Text>
        <UnorderedList>
          <ListItem>
            <Text as="span" fontWeight="bold">
              ID
            </Text>
            : {t("Identyfikator weryfikacji")}
          </ListItem>
          <ListItem>
            <Text as="span" fontWeight="bold">
              TYPE
            </Text>
            : {t("Typ pobieranego pliku (HTML, PDF lub JSON)")}
          </ListItem>
          <ListItem>
            <Text as="span" fontWeight="bold">
              LANG
            </Text>
            : {t("Język wyniku weryfikacji (pl lub en), opcjonalny")}
          </ListItem>
          <ListItem>
            <Text as="span" fontWeight="bold">
              TIMEZONE
            </Text>
            :{" "}
            {t(
              "Przekazanie strefy czasowej w parametrze timezone spowoduje spójną prezentację wszystkich dat w raporcie w przekazanej strefie. Strefę czasową należy przekazywać w formacie zgodnym ze standardem IANA."
            )}
          </ListItem>
        </UnorderedList>
        <Text fontWeight="bold" mt={4}>
          Example:
        </Text>
        <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
          {t(
            `curl -H "api-auth: <API_KEY>" https://${customPart}.weryfikacjapodpisu.pl/api/v2/epw/6f19acdb9b0262b9074489d91da460a17fe0f93fb9462a5adf3193130a999dea.pdf?lang=pl`
          )}
        </Code>
      </Box>
      <Divider />

      {/* Extracted file section */}
      {isExtractedFilesEnabled && (
        <>
          <Box mb={8}>
            <Heading as="h2" size="lg" mb={4}>
              {t("Pobieranie Wyodrębnionego Pliku")}
            </Heading>
            <Heading as="h3" size="md" mb={2}>
              GET
            </Heading>
            <Text mb={2}>
              {t(
                "Ten endpoint umożliwia pobranie wyodrębnionego pliku, który został wygenerowany podczas weryfikacji z parametrem `extractSignedFiles`. Plik jest dostępny do pobrania przez godzinę od momentu weryfikacji."
              )}
            </Text>
            <Text fontWeight="bold" mt={4}>
              HTTP Request:
            </Text>
            <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
              {`GET https://${customPart}.weryfikacjapodpisu.pl/api/extractedFiles/<fileId>`}
            </Code>
            <Text fontWeight="bold" mt={4}>
              Parameters:
            </Text>
            <UnorderedList>
              <ListItem>
                <Text as="span" fontWeight="bold">
                  fileId
                </Text>
                :{" "}
                {t(
                  "Identyfikator pliku, który można pobrać z wyniku weryfikacji (extractedFiles.fileId)."
                )}
              </ListItem>
            </UnorderedList>
            <Text fontWeight="bold" mt={4}>
              Example:
            </Text>
            <Code p={2} borderRadius="md" bg="gray.100" wordBreak="break-all">
              {t(
                `curl -H "api-auth: <API_KEY>" https://${customPart}.weryfikacjapodpisu.pl/api/extractedFiles/bee87677aa926f386a7cc014a4f5e101`
              )}
            </Code>
            <Text mt={4}>
              {t(
                "Uwaga: Plik jest dostępny do pobrania tylko przez godzinę od momentu weryfikacji."
              )}
            </Text>
          </Box>
          <Divider />
        </>
      )}
      {/* Contact Section */}
      <Box mb={8}>
        <Heading as="h2" size="lg" mb={4}>
          {t("Kontakt")}
        </Heading>
        <Text mb={2}>
          {t(
            "Jeśli napotkałeś problemy z naszym API, nie wahaj się skontaktować z nami! Jesteśmy tutaj, aby pomóc i zapewnić, że Twoje doświadczenie z naszym produktem jest jak najlepsze. Nasz zespół wsparcia technicznego jest dostępny, aby odpowiedzieć na Twoje pytania i rozwiązać wszelkie napotkane trudności. Skontaktuj się z nami przy użyciu formularza kontaktowego dostępnego na stronie weryfikacjapodpisu.pl, aby uzyskać szybką i profesjonalną pomoc!"
          )}
        </Text>
      </Box>
    </VStack>
  );
};
