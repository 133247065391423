import { ComponentProps } from "react";

import { mdiUploadCircleOutline } from "@mdi/js";

import { Icon } from "./Icon";

export const UploadIcon = (
  props: Omit<ComponentProps<typeof Icon>, "path">
) => {
  return <Icon {...props} path={mdiUploadCircleOutline} />;
};
