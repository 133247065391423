import {
  Button,
  ButtonGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { Modal } from "components/Dialog";

import { FileTreeItem } from "modules/verification/application/types/IVerificationHistoryRecord";

import { FileTree } from "./FileTree";

type Payload = FileTreeItem[];

function countFilesRecursive(files: FileTreeItem[]): number {
  return files.reduce(
    (count, file) => count + 1 + countFilesRecursive(file.children),
    0
  );
}

const id = `verified-files-modal`;

export const VerifiedFilesModal = () => {
  return (
    <Modal<Payload> id={id} size="3xl">
      {({ onClose, payload }) => {
        if (!payload) {
          return null;
        }

        const totalFiles = countFilesRecursive(payload);

        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t("Pliki poddane weryfikacji ({{filesCount}})", {
                  filesCount: totalFiles,
                })}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <FileTree requestFilesTree={payload} />
              </ModalBody>

              <ModalFooter>
                <ButtonGroup gap="2" justifyContent="end">
                  <Button onClick={onClose}>{t("Zamknij")}</Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};
